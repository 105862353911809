import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Image,
  useColorModeValue,
  useToast,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Badge,
  Select,
  Input,
  HStack,
  IconButton,
  TabList,
  TabPanel,
  TabPanels,
  Tab,
  Tabs,
  Link,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import EmptyRouteAlert from '../components/EmptyRouteAlert';
import HeadingTitle from '../components/HeadingTitle';
import { useStoreState, useStoreActions } from '../store';
import {
  FETCH_PLAYERS,
  GET_PARTICIPATION_BY_ID,
  GET_PARTICIPATION_BY_NAME,
  GET_PARTICIPATION_INVITES,
  GET_PLAYER,
  GET_TEAM_BY_NAME,
  GET_TEAM_PLAYERS,
} from '../utils/queries';
import Footer from './Footer';
import Sidebar from './Navigation';
import Loader from './Loader';
import TeamCard from '../components/TeamCard';
import { PlayerInterface } from './Player';
import dayjs from 'dayjs';
import { greenishColor, redishColor } from '../utils/theme';
import PlayerData from '../components/PlayerData';
import useLeaguesState from '../hooks/useLeaguesState';
import usePlayerInfo from '../hooks/usePlayerInfo';
import {
  CREATE_PARTICIPATION,
  UPDATE_PLAYER,
  CREATE_INVITES,
} from '../utils/mutations';
import { isElite, mapKeyToName, timeout } from '../utils/helper';
import RegistrationComponent from './League/Tournaments/RegitrationComponent';
import useDebounce from '../hooks/useDebounce';
import Logo from '../assets/AL-Blue-Logo.png';
import { CloseIcon } from '@chakra-ui/icons';
import { usePayments } from '../hooks/usePaymentState';
import { Payment } from '../utils/types';

const TeamPage = () => {
  const playerInfo = usePlayerInfo();
  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selection, setSelection] = useState('');
  const toast = useToast();
  const [createInvites] = useMutation(CREATE_INVITES);
  const [getPlayer] = useLazyQuery(GET_PLAYER);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fetchParticipation] = useLazyQuery(GET_PARTICIPATION_BY_NAME);
  const [fetchTeam] = useLazyQuery(GET_TEAM_BY_NAME);
  const [createParticipation] = useMutation(CREATE_PARTICIPATION);
  const [playerSearchName, setPlayerSearchName] = useState('');
  const [updatePlayer] = useMutation(UPDATE_PLAYER);
  const [error, setError] = useState('');

  const [selectedPlayers, setSelectedPlayers] = useState<
    Array<PlayerInterface>
  >([]);

  const debouncedSearchName = useDebounce(playerSearchName, 350);

  const { data: pData, loading: teamLoading } = useQuery(
    GET_PARTICIPATION_BY_ID,
    {
      variables: { id: id },
    }
  );
  const {
    currentParticipation,
    currentTeam,
    isTransferMarket,
    currentCompetition,
    currentSeason,
    isTeamAdmin,
    isTeamFull,
  } = useLeaguesState(pData?.participation?.competition?.name);

  const { payments: teamPayments } = usePayments({
    participation: { id: { equals: currentParticipation?.id } },
  });
  const { data: invitesData } = useQuery(GET_PARTICIPATION_INVITES, {
    variables: {
      partId: currentParticipation?.id,
      invitedBy: playerInfo?.id,
    },
  });

  const { data: playersRes, loading: playerResLoading } = useQuery(
    FETCH_PLAYERS,
    {
      variables: {
        seasonId: currentSeason?.id,
        compId: currentCompetition?.id,
        firstName: debouncedSearchName?.split(' ')[0] ?? '',
        lastName: debouncedSearchName?.split(' ')[1]
          ? debouncedSearchName?.split(' ')[1]
          : debouncedSearchName?.split(' ')[0] ?? '',
        email: debouncedSearchName,
      },
    }
  );

  const teamData = useMemo(() => {
    return pData?.participation?.teams[0];
  }, [pData]);

  const participationId = useMemo(
    () => (pData && pData.participation ? pData.participation.id : ''),
    [pData]
  );

  const isSameTeam = useMemo(() => {
    return currentParticipation?.id === participationId;
  }, [currentParticipation, participationId]);

  const { data: playersData, loading: playersLoading } = useQuery(
    GET_TEAM_PLAYERS,
    {
      variables: { id: participationId },
    }
  );

  const teamPlayers: Array<PlayerInterface> | null = playersData?.players;

  const availableJerseyNumbers = useMemo(() => {
    const allJerseyNumbers = Array.from(Array(99).keys()).map((x) => x + 1);
    if (teamPlayers && teamPlayers.length) {
      const numbers = teamPlayers.map((x: any) => x.jerseyNumber);
      return allJerseyNumbers.filter((x) => !numbers.includes(x));
    }
    return allJerseyNumbers;
  }, [teamPlayers]);

  const invalidRoute = useMemo(() => {
    if (id && !teamPlayers) {
      return true;
    }
    return false;
  }, [id, teamPlayers]);

  const { userModel: userState } = useStoreState((state) => state);
  const { isDark } = userState;
  const { userModel: userActions } = useStoreActions((state) => state);

  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const isVerified = useMemo(() => {
    if (teamPlayers && teamPlayers.length) {
      const verified = teamPlayers.filter((x) => x.verified);

      return verified.length > 15;
    }
    return undefined;
  }, [teamPlayers]);

  const isLoading = playersLoading || teamLoading;

  // const avAge = useMemo(() => {
  //   if (teamPlayers && teamPlayers.length) {
  //     const avg =
  //       teamPlayers.reduce((acc, p) => {
  //         let dateToNow = dayjs().diff(dayjs(p.dateOfBirth), 'years');
  //         if (isNaN(dateToNow)) {
  //           let dateString = p.dateOfBirth;
  //           dateString =
  //             dateString.substr(6, 4) +
  //             '-' +
  //             dateString.substr(3, 2) +
  //             '-' +
  //             dateString.substr(0, 2);

  //           dateToNow = dayjs().diff(new Date(dateString), 'years');
  //         }
  //         return acc + (isNaN(dateToNow) ? 0 : Number(dateToNow));
  //       }, 0) / teamPlayers.length;

  //     return Math.ceil(avg);
  //   }
  //   return '';
  // }, [teamPlayers]);

  // const returnAge = (date: string) => {
  //   let dateToNow = dayjs(new Date()).diff(date, 'years');
  //   if (isNaN(dateToNow)) {
  //     let dateString = date;
  //     dateString =
  //       dateString.substr(6, 4) +
  //       '-' +
  //       dateString.substr(3, 2) +
  //       '-' +
  //       dateString.substr(0, 2);
  //     dateToNow = dayjs(new Date()).diff(new Date(dateString), 'years');
  //   }
  //   return dateToNow;
  // };

  const handleLeaveTeam = async () => {
    try {
      setIsSubmitting(true);
      toast({
        title: `Leaving team ${currentTeam?.name}`,
        status: 'loading',
        isClosable: true,
      });

      const appriviateCompetitionName = `${
        isElite(currentCompetition?.name)
          ? ''
          : ' ' + mapKeyToName(currentCompetition?.name)
      }`;
      const tfMarketName = `${currentSeason?.name} Transfer Market${appriviateCompetitionName}`;

      let pRes: any;
      pRes = await fetchParticipation({
        variables: { name: tfMarketName },
      });

      if (!pRes?.data?.participation) {
        toast({
          title: `Creating a new Transfer Market team because no transfer team found for this season...`,
          status: 'loading',
          isClosable: true,
        });

        const teamRes = await fetchTeam({
          variables: {
            name: 'Transfer Market',
          },
        });
        if (!teamRes?.data?.team) {
          toast({
            title: `No team found`,
            status: 'error',
            isClosable: true,
          });
          return;
        }
        let participationData = {
          name: tfMarketName,
          seasons: { connect: [{ id: currentSeason?.id }] },
          teams: { connect: [{ id: teamRes?.data?.team?.id }] },
          competition: { connect: { id: currentCompetition?.id } },
          isVerified: false,
        };
        if (!currentCompetition?.id) {
          toast({
            title: `No competition found`,
            status: 'error',
            isClosable: true,
          });
          return;
        }
        const partData = await createParticipation({
          variables: { data: participationData },
        });
        if (partData?.data?.createParticipation?.id) {
          toast({
            title: `Transfer Market team created successfully!`,
            status: 'success',
            isClosable: true,
          });

          pRes = {
            data: {
              participation: { id: partData?.data?.createParticipation?.id },
            },
          };
        }
      }
      const updatedPlayerData = {
        participation: {
          disconnect: [{ id: currentParticipation?.id }],
          connect: [{ id: pRes?.data?.participation?.id }],
        },
      };
      await updatePlayer({
        variables: { id: playerInfo?.id, data: updatedPlayerData },
      });
      const res = await getPlayer({ variables: { id: playerInfo?.id } });
      if (res && res?.data) {
        const values = res?.data?.player ?? res?.data;
        const newPlayerInfo = values;
        localStorage.setItem('playerInfo', JSON.stringify(newPlayerInfo));
      }
      toast({
        title: `You've been kicked off team ${currentTeam?.name}`,
        status: 'success',
        isClosable: true,
      });
      window.location.reload();
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
      toast({
        title: error.message,
        status: 'error',
        isClosable: true,
      });
      setIsSubmitting(false);
    }
  };

  const unwantedFields = useMemo(() => {
    let initial = ['password', 'teamName', 'sportType'];
    if (currentParticipation?.type !== 'Football') {
      initial = [...initial, 'kitSize', 'jerseyNumber'];
    }
    return initial;
  }, [currentParticipation]);

  const handleSelectedPlayer = async (
    player: PlayerInterface,
    isDelete?: boolean
  ) => {
    if (player) {
      setError('');
      if (isDelete) {
        setSelectedPlayers(selectedPlayers.filter((p) => p.id !== player.id));
      } else {
        if (
          invitesData?.invites?.length &&
          invitesData?.invites?.find((i: any) => i.player?.id === player.id)
        ) {
          setError('Player already invited');
          return;
        }
        if (
          currentCompetition?.maxPlayersCount &&
          currentParticipation?.playersCount &&
          invitesData?.invites.length &&
          invitesData?.invites.length +
            selectedPlayers.length +
            currentParticipation?.playersCount >=
            currentCompetition?.maxPlayersCount
        ) {
          setError('Maximum players count reached');
          return;
        }

        if (!selectedPlayers.find((p) => p.id === player.id)) {
          setSelectedPlayers([...selectedPlayers, player]);
        }
      }
    }
  };

  const handleSendInvitation = async () => {
    try {
      setIsSubmitting(true);
      if (!selectedPlayers.length) throw new Error('No player selected');
      const list = selectedPlayers.map((p: PlayerInterface) => {
        return {
          participation: {
            connect: { id: currentParticipation?.id },
          },
          invitedBy: {
            connect: { id: playerInfo?.id },
          },
          player: {
            connect: {
              id: p.id,
            },
          },
        };
      });

      await createInvites({ variables: { data: list } });
      toast({
        title: `Invitation/s sent successfully!`,
        status: 'success',
        isClosable: true,
      });
      await timeout(1000);
      window.location.reload();
    } catch (error) {
      console.log(error);
      toast({
        title: error.message,
        status: 'error',
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Sidebar>
      <Stack
        bg="white"
        minH="100vh"
        pt={{ base: '125px', md: '200px' }}
        display="flex"
        justifyContent={'space-between'}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <Box>
            <Modal
              isOpen={isOpen}
              onClose={() => {
                onClose();
                setPlayerSearchName('');
                setSelection('');
              }}
              size="xl"
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Add new player</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb="50px">
                  {!currentCompetition?.disableEdits &&
                  !currentCompetition?.disableRegistration ? (
                    <Box>
                      <Select
                        onChange={(e) => setSelection(e.target.value)}
                        value={selection}
                      >
                        <option value="">Choose selection</option>
                        <option value="existing">
                          Invite player from Amateurs League
                        </option>
                        <option value="new">
                          Add new non-registered player
                        </option>
                      </Select>
                      {selection === 'existing' ? (
                        <Box mt="30px">
                          <Box p={5} bg={'gray.100'} rounded="6px" mb="10px">
                            Please note that invites get auto deleted after 10
                            days
                          </Box>
                          {selectedPlayers.length ? (
                            <Box mb="20px">
                              <Text>
                                Selected player to send invitation to:
                              </Text>
                              <HStack mt="20px" flexWrap="wrap">
                                {selectedPlayers.map((p: PlayerInterface) => {
                                  return (
                                    <Box
                                      key={p.id}
                                      bg="gray.100"
                                      p="10px 20px"
                                      borderRadius="6px"
                                    >
                                      {p.firstName} {p.lastName}{' '}
                                      <IconButton
                                        size="xs"
                                        margin="0"
                                        icon={<CloseIcon />}
                                        aria-label=""
                                        onClick={() =>
                                          handleSelectedPlayer(p, true)
                                        }
                                      />
                                    </Box>
                                  );
                                })}
                              </HStack>
                            </Box>
                          ) : null}
                          {error ? (
                            <Text color="red" my="10px">
                              {error}
                            </Text>
                          ) : null}
                          <Box position="relative">
                            <Input
                              value={playerSearchName}
                              onChange={(e) => {
                                setPlayerSearchName(e.target.value);
                                setError('');
                              }}
                              placeholder="Search by player name"
                              size="lg"
                              fontSize="xl"
                              fontWeight="bold"
                              p="30px"
                              textAlign={'center'}
                            />
                            {debouncedSearchName ? (
                              <Box>
                                {playerResLoading ? (
                                  <Loader />
                                ) : (
                                  <Box
                                    bg="white"
                                    borderRadius="6px"
                                    boxShadow={'0 0 10px 0 rgba(0, 0, 0, 0.1)'}
                                    mt="10px"
                                  >
                                    {playersRes?.players?.length ? (
                                      <Box maxH="350px" overflowY="auto">
                                        {playersRes?.players?.map(
                                          (p: PlayerInterface) => {
                                            return (
                                              <HStack
                                                alignItems="center"
                                                p="10px 30px"
                                                cursor={'pointer'}
                                                fontWeight={'bold'}
                                                _hover={{
                                                  bg: 'teal.50',
                                                }}
                                                borderBottom="1px solid #f0f0f0"
                                                onClick={() =>
                                                  handleSelectedPlayer(p)
                                                }
                                              >
                                                <Image
                                                  src={p.photo?.url}
                                                  boxSize="50px"
                                                  objectFit={'cover'}
                                                  fallbackSrc={Logo}
                                                />
                                                <Box>
                                                  {p.firstName} {p.lastName}
                                                </Box>
                                              </HStack>
                                            );
                                          }
                                        )}
                                      </Box>
                                    ) : (
                                      <Center p="15px">No data found!</Center>
                                    )}
                                  </Box>
                                )}
                              </Box>
                            ) : null}
                          </Box>

                          <Box textAlign={'center'} mt="30px">
                            <Button
                              isDisabled={
                                !selectedPlayers.length || isSubmitting
                              }
                              isLoading={isSubmitting}
                              bg={greenishColor}
                              _hover={{ bg: 'teal.400' }}
                              color="white"
                              onClick={handleSendInvitation}
                            >
                              Sent Invitation
                            </Button>
                          </Box>
                        </Box>
                      ) : selection === 'new' ? (
                        <RegistrationComponent
                          compName={currentCompetition?.name}
                          teamId={currentTeam?.id}
                          participationId={currentParticipation?.id}
                          from="Other"
                          unwantedFields={unwantedFields}
                          btnText={'Add Player'}
                          onComplete={() => {
                            onClose();
                            setPlayerSearchName('');
                            window.location.reload();
                          }}
                        />
                      ) : null}
                    </Box>
                  ) : (
                    <Center>
                      League is disabled for either registration or edits
                    </Center>
                  )}
                </ModalBody>
              </ModalContent>
            </Modal>
            {!isLoading && invalidRoute ? (
              <Box mt="150px">
                <EmptyRouteAlert
                  title={
                    id && teamPlayers
                      ? 'WRONG PLAYER SECURITY CODE, OR PLAYER DOES NOT EXIST'
                      : ''
                  }
                />
              </Box>
            ) : (
              <Box textAlign={'center'} mb="50px">
                <HeadingTitle>{'Team Information'}</HeadingTitle>

                {isTeamAdmin &&
                isSameTeam &&
                !currentCompetition?.disableEdits &&
                !currentCompetition?.disableRegistration ? (
                  <Box mt="20px">
                    <Text fontSize="lg">
                      Please share the security code below with your teammates
                      in order to register
                    </Text>
                    <Heading as="h3" color={greenishColor}>
                      {participationId}
                    </Heading>

                    <Center my="20px">Or</Center>

                    <Button
                      bg={redishColor}
                      _hover={{ bg: greenishColor }}
                      color="white"
                      onClick={onOpen}
                      isDisabled={isSubmitting || Boolean(isTeamFull)}
                    >
                      {isTeamFull
                        ? 'Maximum players count reached'
                        : 'Add new Player'}
                    </Button>

                    {/* <Box mt="20px">
                      <ChakraLink
                        href={paymentLinks.team}
                        target="_blank"
                        textDecor="none !important"
                      >
                        <Button
                          bg={redishColor}
                          color={'white'}
                          _hover={{ bg: 'red.700' }}
                        >
                          Team Payment Link
                        </Button>
                      </ChakraLink>
                    </Box> */}
                  </Box>
                ) : null}
              </Box>
            )}

            {teamPlayers ? (
              <Box padding="0 15px" mt="20px" position="relative">
                <Badge
                  rounded="full"
                  bg="yellow.400"
                  position="absolute"
                  right="0"
                  left="0"
                  margin="0 auto -20px auto"
                  width="fit-content"
                  fontSize="16px"
                  px="20px"
                >
                  {currentParticipation?.type}
                </Badge>
                <TeamCard
                  team={teamData}
                  competition={pData?.participation?.competition}
                />

                <Flex alignItems="center" maxW="1200px" margin="0 auto">
                  <Box
                    flex="1"
                    pl={
                      playerInfo?.id && !isTransferMarket && isSameTeam
                        ? '121px'
                        : '0'
                    }
                  >
                    {playerInfo?.isCaptain && isSameTeam ? (
                      <Box textAlign="center" mb="20px">
                        <Heading as="h3">
                          Team is{' '}
                          <Text as="span">{isVerified ? '' : 'not'}</Text>{' '}
                          <Text
                            as="span"
                            color={isVerified ? greenishColor : redishColor}
                          >
                            verified
                          </Text>{' '}
                        </Heading>
                      </Box>
                    ) : (
                      <Box textAlign="center">
                        {/* <Heading
                          as="h5"
                          fontSize="20px"
                        >{`Average Age: ${avAge}`}</Heading> */}
                      </Box>
                    )}
                  </Box>

                  {playerInfo?.id &&
                  !isTransferMarket &&
                  isSameTeam &&
                  !currentCompetition?.disableEdits &&
                  !currentCompetition?.disableRegistration ? (
                    <Button
                      colorScheme="red"
                      onClick={handleLeaveTeam}
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      Leave Team
                    </Button>
                  ) : null}
                </Flex>

                <Box
                  border="1px"
                  borderColor={borderColor}
                  maxW="1200px"
                  margin="0 auto"
                  rounded="10px"
                >
                  <Tabs colorScheme="teal">
                    <TabList>
                      <Tab p={4}>Team Players</Tab>
                      {(isTeamAdmin || playerInfo?.isCaptain) && isSameTeam ? (
                        <Tab p={4}>
                          <Flex gap="5px">
                            <Text>Invites </Text>
                            {invitesData?.invites?.length ? (
                              <Box
                                boxSize="20px"
                                fontSize="xs"
                                rounded="full"
                                bg={greenishColor}
                                color="white"
                                display="flex"
                                alignItems="center"
                                justifyContent={'center'}
                              >
                                {invitesData?.invites?.length}
                              </Box>
                            ) : null}
                          </Flex>
                        </Tab>
                      ) : null}
                      {playerInfo?.id && isSameTeam ? (
                        <Tab p={4}>My Payments</Tab>
                      ) : null}
                      {(isTeamAdmin || playerInfo?.isCaptain) && isSameTeam ? (
                        <Tab p={4}>Team Payments</Tab>
                      ) : null}
                    </TabList>
                    <TabPanels>
                      <TabPanel p={4}>
                        <TableContainer>
                          <Table variant="simple">
                            <Thead>
                              <Tr>
                                <Th>Player</Th>
                                <Th>Photo</Th>
                                {isSameTeam && playerInfo?.id ? (
                                  <Th>Email</Th>
                                ) : null}
                                {currentParticipation?.type !== 'Football' ? (
                                  <>
                                    {' '}
                                    <Th>Kit Size</Th>
                                    <Th>Jersey Name</Th>
                                  </>
                                ) : (
                                  <>
                                    {' '}
                                    <Th>Kit Size</Th>
                                    <Th>Player Number</Th>
                                  </>
                                )}
                                <Th>Details</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {teamPlayers.map((player: PlayerInterface) => {
                                return (
                                  <Tr key={player.id}>
                                    <Td
                                      textTransform={'capitalize'}
                                      fontWeight="bold"
                                    >
                                      <PlayerData
                                        size="50px"
                                        isCaptain={
                                          playerInfo?.isCaptain &&
                                          isSameTeam &&
                                          currentParticipation?.type ===
                                            'Football'
                                        }
                                        img={player.photo?.url}
                                        player={player}
                                        jerseyNumbers={availableJerseyNumbers}
                                        comp={
                                          pData?.participation?.competition
                                            ?.name
                                        }
                                      >
                                        <Text
                                          as="span"
                                          color={redishColor}
                                          _hover={{ textDecor: 'underline' }}
                                        >
                                          {player.firstName} {player.lastName}{' '}
                                          {player.isCaptain ? '(C)' : null}
                                        </Text>
                                      </PlayerData>
                                    </Td>
                                    <Td>
                                      <Box w="50px" mr="auto">
                                        <PlayerData
                                          size="50px"
                                          img={player.photo?.url}
                                        />
                                      </Box>
                                    </Td>
                                    {isSameTeam && playerInfo?.id ? (
                                      <Td>{player.email}</Td>
                                    ) : null}

                                    {currentParticipation?.type !==
                                    'Football' ? (
                                      <>
                                        {' '}
                                        <Td>{player.kitSize}</Td>
                                        <Td>{player.jerseyNumber}</Td>
                                      </>
                                    ) : (
                                      <>
                                        <Td>{player.kitSize}</Td>
                                        <Td>{player.jerseyNumber}</Td>
                                      </>
                                    )}
                                    <Td>
                                      <Link
                                        href={`/player/${player.id}`}
                                        color="teal.500"
                                      >
                                        Player Details
                                      </Link>
                                    </Td>
                                  </Tr>
                                );
                              })}
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                      {(isTeamAdmin || playerInfo?.isCaptain) && isSameTeam ? (
                        <TabPanel p={4}>
                          {invitesData?.invites?.length ? (
                            <TableContainer>
                              <Table variant="simple">
                                <Thead>
                                  <Tr>
                                    <Th>Player</Th>
                                    <Th>Photo</Th>
                                    <Th>Status</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {invitesData?.invites?.map((invite: any) => {
                                    return (
                                      <Tr key={invite.id}>
                                        <Td
                                          textTransform={'capitalize'}
                                          fontWeight="bold"
                                        >
                                          <PlayerData
                                            size="50px"
                                            img={invite.player?.photo?.url}
                                          >
                                            <Text
                                              as="span"
                                              color={redishColor}
                                              _hover={{
                                                textDecor: 'underline',
                                              }}
                                            >
                                              {invite.player?.name}
                                            </Text>
                                          </PlayerData>
                                        </Td>
                                        <Td>
                                          <Box w="fit-content" mr="auto">
                                            <PlayerData
                                              size="50px"
                                              img={invite.player?.photo?.url}
                                            />
                                          </Box>
                                        </Td>
                                        <Td>
                                          <Badge>{invite.status}</Badge>
                                        </Td>
                                      </Tr>
                                    );
                                  })}
                                </Tbody>
                              </Table>
                            </TableContainer>
                          ) : (
                            <Center>No Data Found!</Center>
                          )}
                        </TabPanel>
                      ) : null}
                      {playerInfo?.id && isSameTeam ? (
                        <TabPanel p={4}>
                          {teamPayments?.length &&
                          teamPayments?.filter(
                            (p: Payment) =>
                              p.player && p.player?.id === playerInfo?.id
                          )?.length ? (
                            <TableContainer>
                              <Table variant="simple">
                                <Thead>
                                  <Tr>
                                    <Th>Player</Th>
                                    <Th>Name</Th>
                                    <Th>Status</Th>
                                    <Th>Amount</Th>
                                    <Th>Date</Th>
                                    <Th>Invoice</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {teamPayments
                                    ?.filter(
                                      (p: Payment) =>
                                        p.player &&
                                        p.player?.id === playerInfo?.id
                                    )
                                    ?.map((payment: Payment) => {
                                      return (
                                        <Tr key={payment.id}>
                                          <Td
                                            textTransform={'capitalize'}
                                            fontWeight="bold"
                                          >
                                            {payment.player?.name}
                                          </Td>
                                          <Td
                                            textTransform={'capitalize'}
                                            fontWeight="bold"
                                          >
                                            {payment.plan.name}
                                          </Td>
                                          <Td>
                                            <Badge
                                              rounded="full"
                                              p={'3px 12px'}
                                              colorScheme={
                                                payment.pending ||
                                                !payment.isFulfilled
                                                  ? 'orange'
                                                  : payment.success
                                                  ? 'green'
                                                  : 'red'
                                              }
                                            >
                                              {payment.pending ||
                                              !payment.isFulfilled
                                                ? 'Pending'
                                                : payment.success
                                                ? 'Success'
                                                : 'Failed'}
                                            </Badge>
                                          </Td>
                                          <Td>
                                            {payment.amount} {payment.currency}
                                          </Td>
                                          <Td>
                                            {dayjs(payment.createdAt).format(
                                              'DD/MM/YYYY'
                                            )}
                                          </Td>
                                          <Td>
                                            {!payment.isFulfilled ||
                                            payment.pending ? null : (
                                              <Link
                                                href={`/invoice/${payment.id}`}
                                                colorScheme="teal"
                                              >
                                                View Invoice
                                              </Link>
                                            )}
                                          </Td>
                                        </Tr>
                                      );
                                    })}
                                </Tbody>
                              </Table>
                            </TableContainer>
                          ) : (
                            <Center>No Payments Found</Center>
                          )}
                        </TabPanel>
                      ) : null}

                      {(isTeamAdmin || playerInfo?.isCaptain) && isSameTeam ? (
                        <TabPanel p={4}>
                          {teamPayments?.length ? (
                            <TableContainer>
                              <Table variant="simple">
                                <Thead>
                                  <Tr>
                                    <Th>Player</Th>
                                    <Th>Name</Th>
                                    <Th>Status</Th>
                                    <Th>Amount</Th>
                                    <Th>Date</Th>
                                    <Th>Invoice</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {teamPayments
                                    ?.filter(
                                      (p: Payment) => p.player && p.success
                                    )
                                    .map((payment: Payment) => {
                                      return (
                                        <Tr key={payment.id}>
                                          <Td
                                            textTransform={'capitalize'}
                                            fontWeight="bold"
                                          >
                                            {payment.player?.name}
                                          </Td>
                                          <Td
                                            textTransform={'capitalize'}
                                            fontWeight="bold"
                                          >
                                            {payment.plan.name}
                                          </Td>
                                          <Td>
                                            <Badge
                                              rounded="full"
                                              p={'3px 12px'}
                                              colorScheme={
                                                payment.pending ||
                                                !payment.isFulfilled
                                                  ? 'orange'
                                                  : payment.success
                                                  ? 'green'
                                                  : 'red'
                                              }
                                            >
                                              {payment.pending ||
                                              !payment.isFulfilled
                                                ? 'Pending'
                                                : payment.success
                                                ? 'Success'
                                                : 'Failed'}
                                            </Badge>
                                          </Td>
                                          <Td>
                                            {payment.amount} {payment.currency}
                                          </Td>
                                          <Td>
                                            {dayjs(payment.createdAt).format(
                                              'DD/MM/YYYY'
                                            )}
                                          </Td>
                                          <Td>
                                            {!payment.isFulfilled ||
                                            payment.pending ? null : (
                                              <Link
                                                href={`/invoice/${payment.id}`}
                                                colorScheme="teal"
                                              >
                                                View Invoice
                                              </Link>
                                            )}
                                          </Td>
                                        </Tr>
                                      );
                                    })}
                                </Tbody>
                              </Table>
                            </TableContainer>
                          ) : (
                            <Center>No Payments Found</Center>
                          )}
                        </TabPanel>
                      ) : null}
                    </TabPanels>
                  </Tabs>
                </Box>
              </Box>
            ) : null}
          </Box>
        )}

        <Footer />
      </Stack>
    </Sidebar>
  );
};

export default TeamPage;
